.loaderContainer {
  position: relative;
  /* transform: translate(-50px, -50px); */
}
.loaderSpec {
    position: absolute;
    display: inline-block;
    border: 0px solid #f3f3f3;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}
.loaderblue { border-top: 5px solid #3498db; animation-delay: 0s; animation-duration: 2s; animation-timing-function: ease; animation-direction: normal; }
.loaderjaune { border-top: 5px solid #ffd663; animation-delay: 0s; animation-duration: 1s; animation-timing-function: ease; animation-direction: normal; }
.loadervert { border-top: 5px solid #94bd7b; animation-delay: 0.5s; animation-duration: 2s; animation-timing-function: ease; animation-direction: normal; }

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}